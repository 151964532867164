import User from '@/services/user';

const ShippingRule = {
    list: async() => {
        const self = await User.self();
        if (!self) {
            return [];
        }
        return self.shipping_rules;
    },
};

export default ShippingRule;