<template>
  <div class="fx-main mg-2">
    <div class="container">
      <div>
        <div id="manage-shipping-rules-list" class="fx-main container">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="true"
            :opacity="0.97"
            :z-index="2147480000"
          ></loading>
          <div class="fx-main flex-md-fill">
            <div class="full-width flex-md-fill">
              <div id="FormRules fx-a-i-start">
                <div class="clickable mg-top-2 btn" @click="addRule()">
                  Adicionar Regra
                  <i class="fas fa-plus"></i>
                </div>
                <div class="input-group fx-main mg-bot-2em mg-top-2 fx-start">
                  <div
                    class="default-border-shipping fx-main col-md-2 mg-right-2em fx-wrap"
                    v-for="(rule, index) in shipping_rules"
                    :key="index"
                  >
                    <span class="mg-right-2-em pd-15-px">Preço Mínimo dos Produtos do Pedido:</span>
                    <input
                      type="number"
                      class="default-border wd-100"
                      v-model="rule.min_items_price"
                    />
                    <span class="mg-right-2-em pd-15-px">Preço Mínimo do Frete do Pedido:</span>
                    <input
                      type="number"
                      class="default-border wd-100"
                      v-model="rule.min_shipping_price"
                    />
                    <span class="mg-right-2-em pd-15-px">CEP Mínimo:</span>
                    <input
                      type="text"
                      class="default-border wd-100"
                      v-model="rule.min_zipcode"
                    />
                    <span class="mg-right-2-em pd-15-px">CEP Máximo:</span>
                    <input
                      type="text"
                      class="default-border wd-100"
                      v-model="rule.max_zipcode"
                    />
                    <span class="mg-right-2-em pd-15-px">Expressão Regular de CEP:</span>
                    <input
                      type="text"
                      class="default-border wd-100"
                      v-model="rule.zipcode_regex"
                    />
                    <span class="mg-right-2-em pd-15-px">Valor:</span>
                    <money
                      v-if="rule.modifier_type === 'PRICE'"
                      class="default-border wd-100"
                      type="text"
                      v-model="rule.amount"
                      v-bind="money"
                    ></money>
                    <the-mask
                      v-if="rule.modifier_type === 'PERCENTAGE'"
                      v-model="rule.amount"
                      mask="##%"
                      type="text"
                      :masked="false"
                      class="default-border wd-100"
                    ></the-mask>

                    <div class="fx-main mg-bot-2em mg-top-1">
                      <label>Porcentagem</label>
                      <input
                        type="radio"
                        name="coupon_mod"
                        v-model="rule.modifier_type"
                        value="PERCENTAGE"
                      />
                    </div>
                    <div class="fx-main mg-bot-2em">
                      <label>Preço</label>
                      <input
                        type="radio"
                        name="coupon_mod"
                        v-model="rule.modifier_type"
                        value="PRICE"
                      />
                    </div>

                    <div class="input-group fx-main fx-a-i-cent margin-bottom">
                      <span class="margin-bottom" :for="'hability_rule' + String(index)">Habilitada</span>
                      <div class="switch">
                        <input
                          type="checkbox"
                          class="switch__input"
                          :id="'hability_rule' + String(index)"
                          v-model="rule.enabled"
                          :true-value="true"
                          :false-value="false"
                        />
                        <label :for="'hability_rule' + String(index)" class="switch__label"></label>
                      </div>
                    </div>
                    <div class="input-group fx-main fx-a-i-cent margin-bottom">
                      <span class="margin-bottom" :for="'relative_rule' + String(index)">Relativa</span>
                      <div class="switch">
                        <input
                          type="checkbox"
                          class="switch__input"
                          :id="'relative_rule' + String(index)"
                          v-model="rule.relative"
                          :true-value="true"
                          :false-value="false"
                        />
                        <label :for="'relative_rule' + String(index)" class="switch__label"></label>
                      </div>
                    </div>
                    <div class="input-group fx-main fx-a-i-cent margin-bottom">
                      <span class="margin-bottom" :for="'cumulative_rule' + String(index)">Cumulativa</span>
                      <div class="switch">
                        <input
                          type="checkbox"
                          class="switch__input"
                          :id="'cumulative_rule' + String(index)"
                          v-model="rule.cumulative"
                          :true-value="true"
                          :false-value="false"
                        />
                        <label :for="'cumulative_rule' + String(index)" class="switch__label"></label>
                      </div>
                    </div>

                    <div
                      class="label label-table label-danger clickable mg-top-2 del-img wd-100"
                      @click="eraseRule(index)"
                    >
                      <i class="fas fa-trash wd-100"></i>
                    </div>
                  </div>
                  <div class="btn clickable mg-top-2" @click="saveRules()">
                    Salvar
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.full-width {
  width: 100%;
}

@media (max-width: 500px) {
  .style-button {
    width: 50%;
    font-size: 0.7em;
  }
}

.pd-0 {
  padding: 0 2px;
}
.border-radius-10 {
  border-radius: 10px;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.select-style {
  height: 120px;
  font-weight: bold;
  font-size: 0.9em;
}

.select-style option {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}

.fx-main {
  width: 100%;
}

#FormCoupon {
  margin: 4%;
}

.margin-bottom {
  margin-bottom: 5px;
}

.pad-top {
  padding-top: 10px;
}

input[type="radio"] {
  margin-left: auto;
}

.default-border-shipping {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
  border: .1em solid #006C7D;
}
</style>
<script>
import ShippingRule from "@/services/shipping_rules.js";
import { apiBaseUrl } from "@/services/api.js";
import HeaderBuilder from "@/services/header-builder.js";
import util from "@/services/util";
import { Money } from "v-money";
import axios from "axios";
import { TheMask } from "vue-the-mask";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

export default {
  name: "manage-shipping-rules-list",
  data: function() {
    return {
      shipping_rules: [],
      loadIndex: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  mounted() {
    this.listRules();
  },
  methods: {
    listRules() {
      this.loadIndex++;
      ShippingRule.list()
        .then(rules => {
          this.shipping_rules = rules.map(sprl => {
            if (sprl.modifier_type === "PRICE") {
              sprl.amount = sprl.amount / 100;
            }
            return sprl;
          });
        })
        .finally(() => {
          this.loadIndex--;
        });
    },
    addRule: function() {
      this.shipping_rules = this.shipping_rules || [];
      this.shipping_rules.push({
        min_items_price: null,
        min_shipping_price: null,
        modifier_type: "PERCENTAGE",
        amount: null,
        enabled: true,
        relative: true,
        cumulative: false,
        min_zipcode: "",
        max_zipcode: "",
        zipcode_regex: "",
      });
      this.$forceUpdate();
    },
    eraseRule: function(index) {
      this.shipping_rules.splice(index, 1);
      this.$forceUpdate();
    },
    saveRules: function() {
      this.loadIndex++;
      axios
        .put(
          `${apiBaseUrl}/api/shipping/rules`,
          {
            shipping_rules: this.shipping_rules.map(sprl => {
              sprl = JSON.parse(JSON.stringify(sprl));
              if (sprl.modifier_type === "PRICE") {
                sprl.amount = Number(String(sprl.amount)) * 100;
              } else {
                sprl.amount = Number(String(sprl.amount).replace(/\D/g, ""));
              }
              return sprl;
            })
          },
          {
            headers: HeaderBuilder.build()
          }
        )
        .then(
          response => {
            this.$notify({
              type: "success",
              title: "Regras Salvas",
              text: "Regras Salvas com Sucesso!"
            });
            this.$forceUpdate();
          },
          error => {
            this.$notify({
              type: "error",
              title: "Erro ao Salvar Regras",
              text: util.stringifyAxiosError(error)
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    }
  },
  components: {
    money: Money,
    "the-mask": TheMask,
    Loading
  },
  computed: {
    isLoading: function() {
      return this.loadIndex > 0;
    }
  }
};
</script>